import _isEmpty from 'lodash/isEmpty';
import axios from '@axios';

export default {
  namespaced: true,
  state: () => ({
    projects: [],
  }),
  mutations: {
    setProjects(state, data) {
      state.projects = data;
    },
  },
  getters: {
    getProjects(state) {
      return state.projects;
    },
  },
  actions: {
    async actionGetTree(context) {
      try {
        let res = await axios.get('/ProjectStructureTemplate/get-base');
        if (res.data.succeeded) {
          this.$app.$bvToast.toast('Get Project template successfully!', {
            title: `Success`,
            variant: 'success',
            solid: true,
          });
          return res;
        } else {
          this.$app.$bvToast.toast('Get Project template Failed!', {
            title: `Warning`,
            variant: 'warning',
            solid: true,
          });
        }
      } catch (error) {
        this.$app.$bvToast.toast('Get Project template Failed!', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        });
      }
    },

    async actionAddTree(context, payload) {
      try {
        let res = await axios.post('/TreeItem', payload);
        if (res.data.succeeded) {
          // ElNotification({
          //     title: "Success",
          //     message: "Add Tree Successfully!",
          //     type: "success",
          // });
        } else {
          // ElNotification({
          //     title: "Warning",
          //     message: "Add Tree Failed!",
          //     type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //     title: "Warning",
        //     message: "Add Tree Failed!",
        //     type: "warning",
        // });
      }
    },

    async actionGetCustomReports(context, payload) {
      try {
        let res = await axios.post('/TreeItem/schedule-data', payload);
        if (res.data.succeeded) {
          return res;
        } else {
        }
      } catch (error) {}
    },

    async actionDuplicateTree(context, payload) {
      try {
        let res = await axios.post(`/TreeItem/Duplicate/${payload}`);
        if (res.data.succeeded) {
        } else {
        }
      } catch (error) {}
    },

    async actionUpdateTree(context, payload) {
      try {
        let res = await axios.put(`/TreeItem/${payload.id}`, payload);
        if (res.data.succeeded) {
          this.$app.$bvToast.toast('Edit Tree Successfully!', {
            title: `Success`,
            variant: 'success',
            solid: true,
          });
        } else {
          this.$app.$bvToast.toast('Edit Tree fail!', {
            title: `Warning`,
            variant: 'success',
            solid: true,
          });
        }
      } catch (error) {
        this.$app.$bvToast.toast('Edit Tree fail!', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        });
      }
    },

    async actionDeleteTree(context, payload) {
      try {
        let res = await axios.delete(`/TreeItem/${payload}`);
        if (res.data.succeeded) {
          // ElNotification({
          //     title: "Success",
          //     message: "Delete Tree Successfully!",
          //     type: "success",
          // });
        } else {
          // ElNotification({
          //     title: "Warning",
          //     message: "Delete Tree Failed!",
          //     type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //     title: "Warning",
        //     message: "Delete Tree Failed!",
        //     type: "warning",
        // });
      }
    },

    async actionExportDetailReport(context, payload) {
      try {
        let res = await axios({
          url: `/treeitem/export-detail-schedule`,
          data: payload,
          method: 'POST',
          headers: { Accept: 'application/vnd.ms-excel' },
          responseType: 'blob',
        });

        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        try {
          let downloadName = res.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0];
          link.download = downloadName ? downloadName : 'Khối lượng.xlsx';
        } catch (e) {}

        link.click();
        URL.revokeObjectURL(link.href);

        // ElNotification({
        //     title: "Success",
        //     message: "Tải xuống thành công!",
        //     type: "success",
        // });
      } catch (error) {
        console.log(error);
        // ElNotification({
        //     title: "Warning",
        //     message: "Tải xuống không thành công! " + error.message,
        //     type: "warning",
        // });
      }
    },
  },
};
