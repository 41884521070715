import {
  LOCAL_STORAGE_KEY,
  getValueFromStorageByKey,
} from '../../../libs/storageUtils';
import _isEmpty from 'lodash/isEmpty';
import axios from '@axios';

const checkLogin = () => {
  if (_isEmpty(getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN))) {
    return false;
  }
  return true;
};

const getUserName = () => {
  return getValueFromStorageByKey(LOCAL_STORAGE_KEY.USER_NAME);
};

export default {
  namespaced: true,
  state: () => ({
    isLogin: checkLogin(),
    permissions: [],
    userName: getUserName(),
    userInfo: {},
  }),
  mutations: {
    setIsLogin(state, data) {
      state.isLogin = data;
    },
    setPermissions(state, data) {
      state.permissions = data;
    },
    setUserName(state, data) {
      state.userName = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  getters: {
    getIsLogin(state) {
      return state.isLogin;
    },
    getUserName(state) {
      return state.userName;
    },
    getPermissions(state) {
      return state.permissions;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
  },
  actions: {
    actionSetIsLogin(context, data) {
      context.commit('setIsLogin', data);
    },
    actionSetUserName(context, data) {
      context.commit('setUserName', data);
    },
    actionSetPermissions(context, data) {
      context.commit('setPermissions', data);
    },
    actionSetUserInfo(context, data) {
      context.commit('setUserInfo', data);
    },
    async actionGetUsers(context, payload) {
      try {
        let res = await axios.get('/users/search', { params: payload });
        if (res.data.succeeded) {
          return res;
        } else {
          this.$app.$bvToast.toast('Get Users Failed!', {
            title: `Warning`,
            variant: 'warning',
            solid: true,
          });
        }
      } catch (error) {
        this.$app.$bvToast.toast('Get Users Failed!', {
          title: `Warning`,
          variant: 'warning',
          solid: true,
        });
      }
    },
  },
};
