import Vue from "vue";

// axios
import axios from "axios";
// import https from 'https'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://localhost:44323/v1/api/',

  baseURL: process.env.VUE_APP_API_URL,
  //  baseURL: "http://103.48.192.218:8000/v1/api/",
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});
// axiosIns.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });

// axiosIns.interceptors.request.use(
//   function(config) {
//     config.headers = {
//       Authorization: `Bearer ${getValueFromStorageByKey(
//         LOCAL_STORAGE_KEY.ACCESS_TOKEN
//       )}`,
//       "Access-Control-Allow-Origin": "*",
//     };
//     return config;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );
Vue.prototype.$http = axiosIns;

export default axiosIns;
