
import _isEmpty from "lodash/isEmpty";
import axios from '@axios'


export default {
    namespaced: true,
    state: () => ({
        projects: [],
    }),
    mutations: {
        setProjects(state, data) {
            state.projects = data;
        },
    },
    getters: {
        getProjects(state) {
            return state.projects;
        },
    },
    actions: {
        async actionGetTemplate(context) {
            try {
                let res = await axios.get('/ProjectStructureTemplate/get-base');
                if (res.data.succeeded) {
                    return res;
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Get Project template Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Get Projects template Failed!",
                //     type: "warning",
                // });
            }
        },

        async actionAddTree(context, payload) {
            try {
                let res = await axios.post('/TreeItem', payload);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Add Tree Successfully!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Add Tree Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Add Tree Failed!",
                //     type: "warning",
                // });
            }
        },

        async actionEditProject(context, payload) {
            try {
                let res = await axios.put(`/TreeItem/${payload.id}`, payload);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Edit Tree Successfully!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Edit Tree Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Edit Tree Failed!",
                //     type: "warning",
                // });
            }
        },

        async actionDeleteProject(context, payload) {
            try {
                let res = await axios.delete(`/TreeItem/${payload}`);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Delete Tree Successfully!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Delete Tree Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Delete Tree Failed!",
                //     type: "warning",
                // });
            }
        },

        async actionAddExcel(context, payload) {
            try {
                let formData = new FormData();
                formData.append("file", payload);
                let res = await axios.post('/projectstructuretemplate/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Import thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Import không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                console.log(error.response);
                // ElNotification({
                //     title: "Warning",
                //     message: "Import không thành công!" + error.response.data.message,
                //     type: "warning",
                // });
            }
        },

    }
}