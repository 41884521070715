export const LOCAL_STORAGE_KEY = {
  CURRENT_PROJECT_ID: 'currentProjectId',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USER_NAME: 'userName',
  PERMISSIONS: 'permissions',
  USER_ID: 'USER_ID',
};

export function getValueFromStorageByKey(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    null;
  }
}

export function setValueToStorageByKey(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {}
}

export function removeValueToStorageByKey(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {}
}
