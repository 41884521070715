import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import {createStore} from 'vuex'
import commonModule from './modules/common';
import projectModule from './modules/project';
import templateModule from './modules/project-template';
import treeModule from './modules/tree';
import userModule from './modules/user';
import reportModule from './modules/reports';
import materialModule from './modules/material';
import configModule from './modules/config';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    commonModule,
    projectModule,
    templateModule,
    treeModule,
    userModule,
    reportModule,
    materialModule,
    configModule
  },
  strict: process.env.DEV,
})
