import {
  LOCAL_STORAGE_KEY,
  getValueFromStorageByKey,
} from '../../../libs/storageUtils';
import _isEmpty from 'lodash/isEmpty';
import axios from '@axios';

const fGetCurrentProjectId = () => {
  if (
    _isEmpty(getValueFromStorageByKey(LOCAL_STORAGE_KEY.CURRENT_PROJECT_ID))
  ) {
    return null;
  }
  return getValueFromStorageByKey(LOCAL_STORAGE_KEY.CURRENT_PROJECT_ID);
};

export default {
  namespaced: true,
  state: () => ({
    projects: [],
    currentProjectId: fGetCurrentProjectId(),
  }),
  mutations: {
    setProjects(state, data) {
      state.projects = data;
    },
    setCurrentProjectId(state, data) {
      state.currentProjectId = data;
    },
  },
  getters: {
    getProjects(state) {
      return state.projects;
    },
    getCurrentProjectId(state) {
      return state.currentProjectId;
    },
    getCurrentProject(state) {
      let id = fGetCurrentProjectId();
      if (fGetCurrentProjectId()) {
        return state.projects.find((x) => x.id == id);
      }
      return null;
    },
  },
  actions: {
    async actionGetProjects(context, payload) {
      try {
        let res = await axios.get('/project', { params: payload });
        if (res.data.succeeded) {
          context.commit('setProjects', res.data.data.items);

          if (res.data.data.items.length == 0) {
            setValueToStorageByKey(LOCAL_STORAGE_KEY.CURRENT_PROJECT_ID, '');
          }
          return res;
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Lấy danh sách dự án không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Lấy danh sách dự án không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionGetSchedules(context, payload) {
      try {
        let res = await axios.post('/treeitem', payload);
        if (res.data.succeeded) {
          return res;
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: " Lấy dữ liệu schedule không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: " Lấy dữ liệu schedule không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionGetProject(context, payload) {
      try {
        if (!payload) {
          return;
        }
        let res = await axios.get(`/project/${payload}`);
        if (res.data.succeeded) {
          return res;
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Không lấy được danh sách dự án!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Không lấy được danh sách dự án!",
        //   type: "warning",
        // });
      }
    },

    async actionGetUsersInProject(context, payload) {
      try {
        let res = await axios.get(`/project/users-in-project/${payload}`);
        if (res.data.succeeded) {
          return res;
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Không lấy được danh sách dự án!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Không lấy được danh sách dự án!",
        //   type: "warning",
        // });
      }
    },

    async actionAddProject(context, payload) {
      try {
        let res = await axios.post('/project', payload);
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Thêm dự án thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Thêm dự án không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Thêm dự án không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionAddUsersInProject(context, payload) {
      try {
        let res = await axios.post(
          `/project/${payload.id}/set-users-work-on-project`,
          payload.ids
        );
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Thêm thành viên thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Thêm thành viên không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Thêm thành viên không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionExportProjectPlan(context, payload) {
      try {
        let res = await axios({
          url: '/project/export2/' + payload.id,
          data: payload.treeIds,
          method: 'POST',
          headers: { Accept: 'application/vnd.ms-excel' },
          responseType: 'blob',
        });
        console.log(res);
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Cau-truc-du-an.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);

        // ElNotification({
        //   title: "Success",
        //   message: "Tải xuống thành công!",
        //   type: "success",
        // });
      } catch (error) {
        console.log(error);
        // ElNotification({
        //   title: "Warning",
        //   message: "Tải xuống không thành công! " + error.message,
        //   type: "warning",
        // });
      }
    },

    async actionExportReport(context, payload) {
      try {
        let res = await axios({
          url: `/project/export-report/${payload.id}/${payload.idReport}`,
          data: payload.treeIds,
          method: 'POST',
          headers: { Accept: 'application/vnd.ms-excel' },
          responseType: 'blob',
        });

        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        try {
          let downloadName = res.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0];
          link.download = downloadName
            ? downloadName
            : 'Khối lượng theo hạng mục.xlsx';
        } catch (e) {}

        link.click();
        URL.revokeObjectURL(link.href);

        // ElNotification({
        //   title: "Success",
        //   message: "Tải xuống thành công!",
        //   type: "success",
        // });
      } catch (error) {
        console.log(error);
        // ElNotification({
        //   title: "Warning",
        //   message: "Tải xuống không thành công! " + error.message,
        //   type: "warning",
        // });
      }
    },

    async actionEditProject(context, payload) {
      try {
        let res = await axios.put(`/project/${payload.id}`, payload);
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Sửa dự án thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Sửa dự án không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Sửa dự án không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionSetUserUpload(context, payload) {
      try {
        let res = await axios.put(
          `/project/${payload.treeItemId}/set-user-upload/${payload.userId}`
        );
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Set người upload thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Set người upload không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Set người upload không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionDeleteProject(context, payload) {
      try {
        let res = await axios.delete(`/project/${payload}`);
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Xóa dự án thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Xóa dự án không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Xóa dự án không thành công!",
        //   type: "warning",
        // });
      }
    },

    async actionDeleteUserInProject(context, payload) {
      try {
        let res = await axios.delete(
          `/project/delete-user-in-project/${payload.idProject}/${payload.idUser}`
        );
        if (res.data.succeeded) {
          // ElNotification({
          //   title: "Success",
          //   message: "Xóa thành viên trong dự án thành công!",
          //   type: "success",
          // });
        } else {
          // ElNotification({
          //   title: "Warning",
          //   message: "Xóa thành viên trong dự án không thành công!",
          //   type: "warning",
          // });
        }
      } catch (error) {
        // ElNotification({
        //   title: "Warning",
        //   message: "Xóa thành viên trong dự án không thành công!",
        //   type: "warning",
        // });
      }
    },
  },
};
