export default [
  {
    path: "/data/tree-database",
    name: "data-tree-database",
    component: () => import("@/views/data/tree-database/TreeDataBase.vue"),
  },
  {
    path: "/data/project",
    name: "data-project",
    component: () => import("@/views/data/projects/ProjectList.vue"),
  },
  // {
  //   path: '/admin/configs',
  //   name: 'admin-configs',
  //   component: () => import('@/views/pages/admin/GeneralConfig.vue'),
  // },
  {
    path: "/admin/roles-permissions",
    name: "roles-permissions",
    component: () => import("@/views/pages/admin/RolePermission.vue"),
  },
  {
    path: "/admin/report",
    name: "report-template",
    component: () => import("@/views/pages/report/Report.vue"),
  },
  {
    path: "/admin/user",
    name: "admin-users",
    component: () => import("@/views/pages/admin/User.vue"),
  },
  {
    path: "/admin/user-licensing",
    name: "admin-user-license",
    component: () => import("@/views/license/License.vue"),
  },
  {
    path: "/user/profile",
    name: "user-profile",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },
  {
    path: "/admin/log",
    name: "admin-logs",
    component: () => import("@/views/pages/admin/AdminLog.vue"),
  },
  {
    path: "/admin/setting",
    name: "setting",
    component: () => import("@/views/pages/profile/UserProfile.vue"),
  },
];
