import {
    LOCAL_STORAGE_KEY,
    getValueFromStorageByKey,
  } from '../../../libs/storageUtils';
import _isEmpty from "lodash/isEmpty";
import axios from '@axios'


export default {
    namespaced: true,
    state: () => ({
        projects: [],
    }),
    mutations: {
        setProjects(state, data) {
            state.projects = data;
        },
    },
    getters: {
        getProjects(state) {
            return state.projects;
        },
    },
    actions: {
        async actionGetUsers(context, payload) {
            try {
                let res = await axios.get('/users/search', { params: payload });
                if (res.data.succeeded) {
                    return res;
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Get Users Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Get Users Failed!",
                //     type: "warning",
                // });
            }
        },

        async actionGetPermissions(context) {
            try {
                let res = await axios.get('/permissions/all');
                if (res.data.succeeded) {
                    return res;
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Get permissions Failed!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Get permissions Failed!",
                //     type: "warning",
                // });
            }
        },
        async actionAddUser(context, payload) {
            try {
                let res = await axios.post('/users', payload);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Tạo mới tài khoản thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Tạo mới tài khoản không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Tạo mới tài khoản không thành công! " + error.response.data.message,
                //     type: "warning",
                // });
            }
        },

        async actionUpdateUser(context, payload) {
            try {
                let res = await axios.put('/users', payload);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Sửa tài khoản thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Sửa tài khoản không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Tạo mới tài khoản không thành công! " + error.response.data.message,
                //     type: "warning",
                // });
            }
        },

        async actionSetOnePermission(context, payload) {
            try {
                let res = await axios.put(`/users/${payload.idUser}/set-one-permission/${payload.idPermission}`);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Set vai trò thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Set vai trò không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Set vai trò không thành công!" + error.response.data.message,
                //     type: "warning",
                // });
            }
        },

        async actionDeleteUser(context, payload) {
            try {
                let res = await axios.delete(`/users/${payload}`);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Xóa tài khoản thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Xóa tài khoản không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Xóa tài khoản không thành công!",
                //     type: "warning",
                // });
            }
        },

        async actionResetPasswordUser(context, payload) {
            try {
                let res = await axios.put(`/users/${payload}/reset-password`);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Reset mật khẩu về 123456 thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Reset mật khẩu không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Reset mật khẩu không thành công!",
                //     type: "warning",
                // });
            }
        },


        async actionChangePasswordUser(context, payload) {
            try {
                let res = await axios.put(`/users/change-password`, payload);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Đổi mật khẩu thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Đổi mật khẩu không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Đổi mật khẩu không thành công!",
                //     type: "warning",
                // });
            }
        },
    }
}