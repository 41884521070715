import { LOCAL_STORAGE_KEY } from "../../../libs/storageUtils";
import _isEmpty from "lodash/isEmpty";
import axios from "@axios";

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {
    setProjects(state, data) {
      state.projects = data;
    },
  },
  getters: {
    getProjects(state) {
      return state.projects;
    },
  },
  actions: {
    async actionGetConfigSupplier(context, payload) {
      try {
        let res = await axios.get("/config/1");
        if (res.data.succeeded) {
          return res;
        } else {
          this.$app.$bvToast.toast("Get Config Failed!", {
            title: `Warning`,
            variant: "warning",
            solid: true,
          });
        }
      } catch (error) {
        this.$app.$bvToast.toast("Get Config Failed !!!", {
          title: `Warning`,
          variant: "warning",
          solid: true,
        });
      }
    },

    async actionUpdateConfig(context, payload) {
      try {
        let res = await axios.put("/config/" + payload.id, payload.data);
        if (res.data.succeeded) {
          this.$app.$bvToast.toast("Update Config Success!", {
            title: `Success`,
            variant: "success",
            solid: true,
          });
        } else {
          this.$app.$bvToast.toast("Get Config Failed!", {
            title: `Warning`,
            variant: "warning",
            solid: true,
          });
        }
      } catch (error) {
        this.$app.$bvToast.toast("Update Config Failed!", {
          title: `Warning`,
          variant: "warning",
          solid: true,
        });
      }
    },
  },
};
