
import _isEmpty from "lodash/isEmpty";
import axios from '@axios';


export default {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    getters: {},
    actions: {
        async actionDownloadReport(context, payload) {
            try {
                let res = await axios({
                    url: `/reportexcel/download/${payload}`,
                    method: 'GET',
                    headers: { "Accept": "application/vnd.ms-excel" },
                    responseType: "blob"
                });

                const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)

                try {
                    let downloadName = res.headers["content-disposition"].split('filename=')[1].split(';')[0];
                    link.download = downloadName ? downloadName : "ReportTemplate.xlsx";
                } catch (e) {

                }

                link.click()
                URL.revokeObjectURL(link.href)

                // ElNotification({
                //     title: "Success",
                //     message: "Tải xuống thành công!",
                //     type: "success",
                // });

            } catch (error) {
                console.log(error);
                // ElNotification({
                //     title: "Warning",
                //     message: "Tải xuống không thành công! " + error.message,
                //     type: "warning",
                // });
            }
        },
        async actionGetCodes(context, payload) {
            try {
                let res = await axios.get("/materialcode/all" + "?type=" + payload);
                if (res.data.succeeded) {
                    return res;
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Lấy danh sách mã công tác ,vật tư không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Lấy danh sách mã công tác ,vật tư không thành công!",
                //     type: "warning",
                // });
            }
        },
        async actionGetCode(context, id) {
            try {
                let res = await axios.get(`/materialcode/${id}`);
                if (res.data.succeeded) {
                    return res;
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Lấy danh sách mã công tác ,vật tư không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Lấy danh sách mã công tác ,vật tư không thành công!",
                //     type: "warning",
                // });
            }
        },
        async actionAddCode(context, payload) {
            try {
                let formData = new FormData();
                for (let key in payload) {
                    formData.append(key, payload[key]);
                }

                let res = await axios.post('/materialcode', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Tạo mới thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Tạo mới không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                console.log(error.response);
                // ElNotification({
                //     title: "Warning",
                //     message: "Tạo mới không thành công! " + error.response.data.message,
                //     type: "warning",
                // });
            }
        },
        async actionUpdateCode(context, payload) {
            try {
                let formData = new FormData();
                for (let key in payload) {
                    formData.append(key, payload[key]);
                }

                let res = await axios.put(`/materialcode`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Sửa thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Sửa không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                console.error(error.me);
                // ElNotification({
                //     title: "Warning",
                //     message: "Sửa không thành công!",
                //     type: "warning",
                // });
            }
        },
        async actionDeleteCode(context, payload) {
            try {
                let res = await axios.delete(`/materialcode/${payload}`);
                if (res.data.succeeded) {
                    // ElNotification({
                    //     title: "Success",
                    //     message: "Xóa mẫu thành công!",
                    //     type: "success",
                    // });
                } else {
                    // ElNotification({
                    //     title: "Warning",
                    //     message: "Xóa mẫu không thành công!",
                    //     type: "warning",
                    // });
                }
            } catch (error) {
                // ElNotification({
                //     title: "Warning",
                //     message: "Xóa mẫu không thành công!",
                //     type: "warning",
                // });
            }
        },
    }
}